import dynamic from 'next/dynamic';
import Image from 'next/image';

import { useMarketPicker } from '@hultafors/shared/context/hooks';

import { useGlobal } from '@hultafors/wibe/hooks';

import styles from './footer.module.scss';

const FooterStyled = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.Footer),
);

const FooterOld = dynamic(() =>
  import('../footer-old/footer-old').then((mod) => mod.FooterOld),
);

export const Footer = () => {
  const { footer, global } = useGlobal();
  const { toggle, activeMarket } = useMarketPicker();

  if (!footer.useNewFooter) {
    return <FooterOld />;
  }

  const Logo = () => {
    if (footer?.footerLogo?.url) {
      return (
        <figure className={styles['logo']}>
          <Image
            src={footer?.footerLogo?.url ?? ''}
            alt={footer.footerLogo.alt ?? 'W.Steps'}
            fill
          />
        </figure>
      );
    }

    return null;
  };

  return (
    <FooterStyled
      activeMarket={activeMarket}
      bottomIntro={footer?.bottomIntro ?? ''}
      brandLinks={footer?.hultaforsBrands ?? []}
      className={styles['footer']}
      cookieSettingsLabel={footer?.cookieSettings ?? ''}
      introText={footer.introText ?? ''}
      items={footer?.menuItems ?? []}
      logoAriaLabel={footer?.footerLogo?.alt ?? 'W.Steps'}
      logoIcon={<Logo />}
      moreInfoText={footer?.moreInfoText ?? ''}
      selectCountryLabel={
        footer.selectCountryLabel || global?.changeLanguage || ''
      }
      socialMediaLabel={footer?.socialMediaLabel ?? ''}
      socialMediaMenu={footer?.socialMedia ?? []}
      toggleMarketPicker={toggle}
    />
  );
};
