import Image from 'next/image';
import styled, { css, CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/wibe/helpers';

interface StyledInformationHeroProps {
  $imageMobile?: boolean;
}

export const StyledInformationHero = styled.div<StyledInformationHeroProps>`
  p {
    &.hero-description {
      font-size: ${fontSizes.paragraph16};
      font-style: normal;
      font-weight: ${fontWeights.medium};
      line-height: ${lineHeights.paragraph16};
      text-align: start;
    }

    &.bold {
      font-weight: ${fontWeights.medium};
    }
  }

  .NotFoundView {
    p {
      margin: 0;
    }
  }

  .hero-description {
    p {
      font-size: ${fontSizes.paragraph16};
      font-style: normal;
      font-weight: ${fontWeights.medium};
      line-height: ${lineHeights.paragraph16};
      text-align: start;
    }

    &.bold {
      font-weight: ${fontWeights.medium};
    }
  }

  .hero-text-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin: ${spacing.m} ${spacing.s};
    padding-block-start: ${spacing.m};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      margin: ${spacing.xl} 0 0 0;
      margin-block-end: ${spacing.m};
    }

    &.ContentListView {
      padding-block-start: ${spacing.l};

      p {
        font-size: ${fontSizes.paragraph16};
        font-weight: ${fontWeights.regular};
        line-height: ${lineHeights.paragraph16};
        margin: 0;
        margin-block-start: ${spacing.m};
        text-align: center;
      }
    }
  }

  .hero-title {
    &.contentView {
      color: ${colors.wibeDarkGrey};
      font-size: ${fontSizes.header2};
      font-style: normal;
      font-weight: ${fontWeights.light};
      line-height: ${lineHeights.header2};
      margin: 0;
      margin-block-end: ${spacing.m};
      text-align: start;

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        font-size: ${fontSizes.header1};
        line-height: ${lineHeights.header1};
      }
    }

    &.contactView {
      font-size: ${fontSizes.header3};
      font-style: normal;
      font-weight: ${fontWeights.regular};
      letter-spacing: 1px;
      line-height: ${lineHeights.header3};
      margin: 0;
      text-align: center;
      text-transform: uppercase;

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        font-size: ${fontSizes.header2};
        line-height: ${lineHeights.header2};
      }
    }

    &.NotFoundView {
      font-size: ${fontSizes.header1};
      font-style: normal;
      font-weight: ${fontWeights.regular};
      line-height: ${lineHeights.header1};
      margin: 0;
      margin-block-end: ${spacing.m};
      text-align: center;
      text-align: start;
    }

    &.ContentListView {
      font-size: ${fontSizes.header3};
      font-style: normal;
      font-weight: ${fontWeights.medium};
      letter-spacing: 1px;
      line-height: ${lineHeights.header3};
      margin: 0;
      text-align: center;
      text-transform: uppercase;

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        font-size: ${fontSizes.header2};
        line-height: ${lineHeights.header2};
      }
    }
  }

  .hero-image {
    color: ${({ $imageMobile }) =>
      $imageMobile ? colors.white : colors.black};
    block-size: auto;
    margin-block-start: ${spacing.m};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      background-color: ${colors.white};
      color: ${colors.white};
      margin-block-start: ${spacing.l};
      min-block-size: auto;
    }
  }
`;

interface ImageWrapperProps {
  $aspectRatio?: CSSProperties['aspectRatio'];
  $mobile?: boolean;
}

function imageAspectRatio({ $aspectRatio, $mobile }: ImageWrapperProps) {
  return css`
    aspect-ratio: ${$aspectRatio && $mobile ? $aspectRatio : '16 / 9'};
    object-fit: ${$aspectRatio && $mobile ? 'inherit' : 'cover'};
  `;
}

export const ImageWrapper = styled.div<ImageWrapperProps>`
  position: relative;
  aspect-ratio: ${({ $mobile }) => ($mobile ? '1' : '16 / 9')};
  object-fit: ${({ $mobile }) => ($mobile ? 'inherit' : 'cover')};
  margin-block-start: ${spacing.m};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-block-start: ${spacing.l};
    ${imageAspectRatio};
  }
`;

export const ImageMobile = styled(Image)`
  @media screen and (min-width: ${breakpoints.mobileMax}) {
    display: none;
  }
`;

export const ImageDesktop = styled(Image)<{ $mobile?: string }>`
  display: ${({ $mobile }) => ($mobile ? 'none' : 'inherit')};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    display: block;
  }
`;
