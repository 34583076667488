import { m } from 'framer-motion';
import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/wibe/helpers';

import { GoodWorkEnvironmentChoice } from '../good-work-environment-choice/good-work-environment-choice';
import { Icon } from '../icon/icon';
import { Paragraph } from '../paragraph/paragraph';
import { paragraph14Style } from '../paragraph/paragraph.styled';

const srOnly = css`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;

const showOnScreen = css`
  position: relative;
  width: 50%;
  height: auto;
  clip: initial;
  flex-basis: 50%;
  margin: 0;
  overflow: visible;
  border-bottom: 1px solid ${colors.grey2};
  padding: 12px 0;
`;

interface ModelStyledProps {
  $active?: boolean;
}

export const ModelStyled = styled.div<ModelStyledProps>`
  background: ${colors.white};
  border: thin solid ${colors.grey3};
  padding: ${spacing.s};

  .Top {
    display: flex;
    position: relative;
    gap: ${spacing.s};
  }

  .Toggle-button {
    cursor: pointer;
    position: absolute;
    inset-inline-end: 0;
    inset-block-start: 0;
    background: ${colors.white};
    border: thin solid ${colors.grey2};
    border-radius: 100%;
    block-size: 26px;
    padding: 0;
    inline-size: 26px;
  }

  .PriceWrapper {
    display: flex;
    flex-direction: column;

    .Price {
      align-items: baseline;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: ${breakpoints.largeMobile}) {
        flex-direction: row;
        margin-block-end: 4px;
      }
    }
  }

  .Measure-Image {
    block-size: 104px;
    padding: 12px;
    inline-size: 100px;
  }

  dl {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 0;

    dt,
    dd {
      border-block-end: thin solid ${colors.grey2};
      margin: 0;
      padding-block: ${spacing.xss};
      ${paragraph14Style};
    }

    margin-block-end: ${spacing.s};

    dt {
      color: ${colors.grey4};
      text-align: start;
    }

    dd {
      color: ${colors.black};
      text-align: end;
    }
  }
`;

const sharedTermDefinitionStyle = css`
  border-bottom: thin solid ${colors.grey2};
  margin: 0;
  padding-block: ${spacing.xss};
`;

export const FeatureList = styled.dl`
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 0;
  margin-block: 0 ${spacing.xs};
`;
export const FeatureTerm = styled.dt`
  ${sharedTermDefinitionStyle};
  color: ${colors.grey4};
  text-align: start;
`;
export const FeatureDefinition = styled.dd`
  ${sharedTermDefinitionStyle};
  color: ${colors.black};
  text-align: end;
`;

export const MotionDiv = styled(m.div)`
  overflow: hidden;
`;

export const ImageButton = styled.button`
  appearance: none;
  border: thin solid ${colors.grey2};
  border-radius: 0;
  background: transparent;
  display: inline-block;
  position: relative;
  padding: 8px;
  cursor: pointer;
`;

export const ImageWrapper = styled.span`
  line-height: 0;
  display: block;
  height: 72px;
  width: 72px;

  img {
    object-fit: contain;
  }
`;

export const ZoomIcon = styled(Icon).attrs({
  svg: 'zoom',
  width: 12,
})`
  position: absolute;
  display: block;
  top: 2px;
  right: 2px;
  color: #303e47;
`;

export const StyledTextButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 0;
`;

export const GoodWorkEnvironmentChoiceDesktop = styled(
  GoodWorkEnvironmentChoice,
)`
  display: none;

  @media screen and (min-width: ${breakpoints.largeMobile}) {
    display: flex;
    margin-block-start: auto;
  }
`;

export const GoodWorkEnvironmentChoiceMobile = styled(
  GoodWorkEnvironmentChoice,
)`
  display: flex;
  justify-content: center;
  margin-top: ${spacing.s};

  @media screen and (min-width: ${breakpoints.largeMobile}) {
    display: none;
  }
`;

export const Value = styled(Paragraph).attrs({ styleType: 'paragraph14' })`
  padding-right: ${spacing.xs};
`;

/* This is so that text will not hide behind toggle icon */
export const ModelName = styled(Paragraph).attrs({
  styleType: 'paragraph14Medium',
})`
  margin-bottom: 4px;
  padding-right: 26px;
`;

export const ModalImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  img {
    inline-size: 100%;
    block-size: 100%;
    object-fit: contain;
  }
`;
