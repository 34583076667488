import { CSSProperties, useId } from 'react';

import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { breakpoints } from '@hultafors/shared/constants';

import { Spacing, spacing } from '@hultafors/wibe/helpers';
import { useGlobal } from '@hultafors/wibe/hooks';
import {
  ImageFragment,
  isDocumentsPageLink,
  isInformationListPageLink,
  isInformationPageLink,
  isPagePlug,
  PagePlugItem,
  PagePlugsFragment,
} from '@hultafors/wibe/types';

import { HTag } from '../h-tag/h-tag';
import { Icon } from '../icon/icon';
import { TextButton } from '../text-button/text-button';

import styles from './page-plugs.module.scss';

interface PagePlugsProps extends Omit<PagePlugsFragment, 'plugs'> {
  marginTop?: Spacing;
  withMargin?: boolean;
  style?: React.CSSProperties;
  plugs?: PagePlugItem[];
}

export const PagePlugs: React.FC<PagePlugsProps> = ({
  plugs,
  marginTop,
  style: styleInput,
  spacingBottom,
  spacingTop,
  narrow,
}) => {
  const uid = useId();
  const { global } = useGlobal();
  const { asPath } = useRouter();

  if (!plugs?.length) {
    return null;
  }
  const style: CSSProperties = {
    ...(styleInput || {}),
    '--margin-top': marginTop && spacing[marginTop] ? spacing[marginTop] : '',
  } as CSSProperties;
  const sizes = [
    `(min-width: ${breakpoints.desktopLarge}) 680px`,
    `(min-width: ${breakpoints.mobileMax}) 45vw`,
    `96vw`,
  ].join(', ');

  function plugMapper(item: PagePlugItem) {
    const key = `PagePlug-${uid}-${item.id}`;
    if (isDocumentsPageLink(item)) {
      return (
        <Link
          key={key}
          href={`${asPath}/${item.slug}`}
          className={styles['item']}
        >
          <div className={styles['aspect']}>
            <div className={styles['document']}>
              <Icon svg="file-24" />
            </div>
          </div>
          <HTag className={styles['title']} styleType="header4" type="h4">
            {item.pageHeader}
          </HTag>
          <TextButton arrow>{global?.readMoreButtonLabel || ''}</TextButton>
        </Link>
      );
    }
    const url =
      (isPagePlug(item) ? item.url : `${asPath}/${item.slug}`) || asPath;
    const image: ImageFragment | null | undefined =
      (isInformationListPageLink(item) || isInformationPageLink(item)
        ? item.seo?.teaserImage || global.fallbackImage
        : item.image) || global.fallbackImage;
    const title =
      isInformationListPageLink(item) || isInformationPageLink(item)
        ? item.seo?.title
        : item.title;

    return (
      <Link key={key} href={url} className={styles['item']}>
        <div className={styles['aspect']}>
          <Image
            src={image?.responsiveImage?.src || ''}
            alt={image?.alt || ''}
            sizes={sizes}
            blurDataURL={image?.responsiveImage?.base64 || ''}
            placeholder={image?.responsiveImage?.base64 ? 'blur' : 'empty'}
            fill
            className={styles['image']}
          />
        </div>
        <HTag className={styles['title']} styleType="header4" type="h4">
          {title}
        </HTag>
        <TextButton arrow>{global?.readMoreButtonLabel || ''}</TextButton>
      </Link>
    );
  }
  return (
    <section
      className={clsx(
        styles['section'],
        narrow && styles['narrow'],
        spacingTop && styles['spacing-top'],
        spacingBottom && styles['spacing-bottom'],
      )}
      style={style}
    >
      {plugs.map(plugMapper)}
    </section>
  );
};
