import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';

import { colors, spacing } from '@hultafors/wibe/helpers';

import { HTag } from '../h-tag/h-tag';
import { LinkMarkdownWrapper } from '../link-markdown-wrapper/link-markdown-wrapper';
import { Paragraph } from '../paragraph/paragraph';

export const StyledMaintenanceBlock = styled.section`
  background-color: ${colors.grey1};
  margin: 0;
  width: 100%;
`;

export const Content = styled.div`
  padding: ${spacing.l} ${spacing.side};

  & > div {
    *:last-child {
      margin-block-end: 0;
    }
  }

  h2 {
    margin-block-end: ${spacing.s};
  }
`;

export const Title = styled(HTag).attrs({ styleType: 'header4', type: 'h2' })``;

export const StyledMarkdown = styled(Markdown).attrs({
  options: {
    overrides: {
      a: {
        component: LinkMarkdownWrapper,
        props: {
          style: {
            display: 'block',
          },
        },
      },
      p: {
        component: Paragraph,
        props: {
          styleType: 'paragraph16',
        },
      },
    },
  },
})`
  & > *:not(:last-child) {
    margin-block-end: ${spacing.s};
  }
`;
