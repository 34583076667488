import { useState } from 'react';

import Image from 'next/image';

import { breakpoints, breakpointsRaw } from '@hultafors/shared/constants';
import { isImageFragment } from '@hultafors/shared/helpers';
import { ParsedImage } from '@hultafors/shared/types';

import { useGlobal } from '@hultafors/wibe/hooks';
import { ImageDynamicFragment } from '@hultafors/wibe/types';
import { ImageFragment } from '@hultafors/wibe/types';

import {
  Counter,
  ImageSliderStyled,
  ImgWrapper,
  SliderStyled,
} from './image-slider.styled';

interface ImageSliderProps {
  images?: (ImageDynamicFragment | ParsedImage)[];
}

export const ImageSlider: React.FC<ImageSliderProps> = ({ images = [] }) => {
  const { global } = useGlobal();
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const changedIndex = (index: number) => {
    setCurrentIndex(index);
  };

  const SETTINGS = {
    adaptiveHeight: false,
    afterChange: changedIndex,
    infinite: images.length > 1,
    lazyLoad: false,
    nextArrow: <Arrow className="Next" aria-label={global?.next || ''} />,
    prevArrow: <Arrow className="Prev" aria-label={global?.previous || ''} />,
    responsive: [
      {
        breakpoint: breakpointsRaw.desktop,
        settings: {
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: breakpointsRaw.mobileMax,
        settings: {
          arrows: true,
          dots: false,
        },
      },
    ],
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
  };

  function imageMapper(image: ImageFragment | ParsedImage, index: number) {
    let alt = '';
    let src = '';
    let aspectRatio = '16 / 9';
    if (isImageFragment<ImageFragment>(image)) {
      if (!image.responsiveImage?.src) {
        return null;
      }
      alt = image.alt || '';
      src = image.responsiveImage.src || '';
      aspectRatio = image.responsiveImage.aspectRatio || aspectRatio;
    } else {
      alt = image.alt || '';
      src = image.url || '';
    }
    if (!src) {
      return null;
    }
    const sizes = [
      '(min-width: 1244px) 1180px',
      `(min-width: ${breakpoints.mobileMax}) 94vw`,
      '96vw',
    ].join(', ');
    return (
      <ImgWrapper key={`Carousel-${index}`} $aspectRatio={aspectRatio}>
        <Image src={src} alt={alt} sizes={sizes} fill />
      </ImgWrapper>
    );
  }

  return images.length ? (
    <ImageSliderStyled>
      <SliderStyled {...SETTINGS}>{images.map(imageMapper)}</SliderStyled>
      <Counter>{`${currentIndex + 1} / ${images.length}`}</Counter>
    </ImageSliderStyled>
  ) : null;
};

interface ArrowProps {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  'aria-label'?: string;
}

const Arrow: React.FC<ArrowProps> = ({ className, onClick, ...props }) => {
  return (
    <button
      className={className}
      onClick={onClick}
      aria-label={props['aria-label']}
    />
  );
};
